import React, { useEffect, useState } from 'react'
import Classes from './langSwitcher.module.scss'
import { Link } from 'gatsby'
import AnyLink from '../AnyLink/AnyLink'

const LangSwitcher = ({ locales, currentLocale, relative }) => {
  const [wrapperXY, setWrapperXY] = useState(null)
  
  let currentLang = React.createRef();

  const calcWrapperXY = () => {
    if(currentLang && currentLang.current && window.innerWidth > 959) {
      setWrapperXY({y: currentLang.current.clientHeight + 10, x: currentLang.current.clientWidth + 18})
    }
  }

  useEffect(() => {
    calcWrapperXY()
    window.addEventListener('resize', () => calcWrapperXY())
  }, [])

  return (
    locales && locales.length > 1 ?
          <ul className={["list-unstyled list-inline", Classes.otherLangs].join(' ')}>
          {/* <li ref={currentLang} className={[Classes.currLang, "no-mb text-small"].join(' ')}><span>{locales.find(l => l.lang === currentLocale).title}</span></li> */}
            {locales.map((l, i) => 
              currentLocale !== l.lang ?
              <li key={i}>
                  <AnyLink key={i} link={`/${l.lang !== 'default' ? l.lang + '/' : ''}${l.path}`} linkTitle={l.title}/>
              </li>
              : null
            )}
            
          </ul>
    : null
  )
}

export default LangSwitcher