import React from 'react'
import Img from "gatsby-image"
import { getFixedGatsbyImage, getFluidGatsbyImage } from 'gatsby-storyblok-image'
import Classes from './imageAll.module.scss'

const ImageAll = ({ image, alt, classes, fadeIn, fullWidth, style, backgroundImage, fluid, fixed, width, height, maxWidth, maxHeight, title, quality, isHeader }) => {

  const fixedProps = fixed && image && getFixedGatsbyImage(image.replace('https:', ''), {
    width: width,
    height: height,
    quality: quality || 60
  })

  const fluidProps = fluid && image && getFluidGatsbyImage(image.replace('https:', ''), {
    maxWidth: maxWidth,
    maxHeight: maxHeight,
    quality: quality || 60
  })

  const titleEl = title ? <p className={isHeader ? Classes.isHeader : Classes.imageText}>{ title }</p> : null

 return (
  <>
    {image && fluid && fluidProps ?
            <Img
                loading="eager"
                fadeIn={fadeIn || false}
                fluid={fluidProps}
                className={["img-responsive fluid", Classes.image, classes, backgroundImage ? Classes.backgroundImage: null, fullWidth ? "img-full-width" : null].join(' ')}
                alt={alt}
                style={{height: 'auto'}}
                />
            
        : image && fixed && fixedProps?
            <Img
                loading="eager"
                fadeIn={fadeIn || false}
                fixed={fixedProps}
                className={["img-responsive", Classes.image, classes, backgroundImage ? Classes.backgroundImage: null, fullWidth ? "img-full-width" : null].join(' ')}
                alt={alt}
                style={style}
                />
        : image ?
            <img src={image} 
              className={["img-responsive", Classes.image, classes, backgroundImage ? Classes.backgroundImage: null, fullWidth ? "img-full-width" : null].join(' ')}
                alt={alt}
                style={style}
                />
        : null}
  {titleEl}
</>
    )
}

export default ImageAll