import Page from './page'
import Grid from './grid'
import ComponentNotFound from './component_not_found'
import TextWithMedia from './textWithImage/textWithMedia'
import Header from './header/header'
import Navbar from './layout/navbar/navbar'
import CodeBlock from './codeBlock/codeBlock'
import Cart from './cart/cart'
import Faq from './faq/faq'
import Slider from './slider/slider'
import Gallery from './gallery/gallery'
import InstagramFeed from './instagramFeed/instagramFeed'

const ComponentList = {
  page: Page,
  grid: Grid,
  nav_item: Navbar,
  textWithMedia: TextWithMedia,
  header: Header,
  code_block: CodeBlock,
  faq: Faq,
  slider: Slider,
  gallery: Gallery,
  instagramFeed: InstagramFeed,
  cart: Cart
}

const Components = (type) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components