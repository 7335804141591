import React from 'react'
import SbEditable from 'storyblok-react'
import SexySection from '../ui/sexySection/sexySection'
import ImageAll from '../ui/imageAll/imageAll'
import Classes from './gallery.module.scss'

const Gallery = (props) => {

  const pb = props.blok
  const images = pb.images
  let delay = 200;

  return (
    <SbEditable content={pb}>
      <SexySection>
        <div className="row center">
          <div className={["col col-xs-12 col-md-10 col-lg-8 center", Classes.imageWrapper].join(' ')} >
          {images.map((image, i) => 
            <div key={i} style={image.image_width ? {width: image.image_width + "rem" } : null} className={Classes.imageContainer} data-sal="fade" data-sal-easing="ease" data-sal-duration="1000" data-sal-delay={delay = delay + 100} >
              <ImageAll fluid maxWidth={image.image_width ? image.image_width * 25 : "400"} image={image.image.filename} alt={image.image.alt ? image.image.alt : "logo"} classes={Classes.image} title={image.image.title}/>
            </div>
          )}
          </div>
        </div>
      </SexySection>
    </SbEditable>
  )
}

export default Gallery

