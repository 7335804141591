import React, {useEffect, useState} from 'react'
import SbEditable from 'storyblok-react'
import SexySection from '../ui/sexySection/sexySection'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Classes from './instagramFeed.module.scss'
const axios = require('axios');

const InstagramFeed = (props) => {

  const [windoWidth, setWindowWidth] = useState(typeof window !== 'undefined'? window.innerWidth : null)
  const [instaNodes, setInstaNodes] = useState([])
  
  const resizeHandler = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', resizeHandler)
  })

  useEffect(() => {
    const maxPosts = windoWidth >= "1400" ? 16 : windoWidth >= "960" ? 12 : windoWidth >= "680" ? 9 : 6



    const createInstagramNodes = (res) => {
      const InNodes = res

          const mutatedNodes = InNodes ? InNodes.map(n => {
            return {image: n.media_url, url: n.permalink, caption: n.caption}
          }) : []
          if(mutatedNodes) {
            setInstaNodes(mutatedNodes)
          }
    }

    axios.post(`/.netlify/functions/fetchInsta`, {})
      .then(function (response) {
          if(response.status === 200) {
              console.log('fetched succesfully', response.data)
              createInstagramNodes(response.data && response.data.data && response.data.data.data)
          } else {
            console.log('no insta images')
          }
      })
      .catch(function (error) {
          console.log('error', error)
      })  
  //   axios.get('https://www.instagram.com/parkmuseerne/?__a=1')
  //     .then(function (response) {
  //       console.log(response)
  //         if(response.status === 200) {
  //             createInstagramNodes(response.data && response.data.graphql && response.data.graphql.user && response.data.graphql.user.edge_owner_to_timeline_media && response.data.graphql.user.edge_owner_to_timeline_media.edges)
  //         }
  //     })
  //     .catch(function (error) {
  //         console.log('error', error)
  //     })  

  }, [])



    // const allInstagramPosts = useStaticQuery(graphql`
    // query {
    //     allInstaNode(limit: 20, sort: {fields: timestamp, order: DESC}) {
    //       edges {
    //         node {
    //             id
    //             likes
    //             caption
    //             localFile {
    //               childImageSharp {
    //                 fluid(maxWidth: 600) {
    //                   aspectRatio
    //                   src
    //                   srcSet
    //                   srcWebp
    //                   srcSetWebp
    //                   sizes
    //                   originalImg
    //                   }
    //                 }
    //             }
    //         }
    //     }
    //   }
    // }
    //   `)

    
    // let instagramPosts = allInstagramPosts.allInstaNode.edges.slice(0, maxPosts)
    const text = props.blok.text ? props.blok.text : null

    const StoryblokClient = require('storyblok-js-client')
    let Storyblok = new StoryblokClient({
    accessToken: 'HJYqFBdZr08fxdAVgr1GMAtt'
  }) 


  const createMarkup = (text) => {
    return {
      __html: Storyblok.richTextResolver.render(text),
    }
  }

    return (
        <SbEditable content={props.blok}>
            <SexySection>
            <div className="row center">
              <div className="col col-xs-12 col col-xs-12 col-md-10 col-lg-8">
                <div className="space-xs-up text-center" dangerouslySetInnerHTML={createMarkup(text)} data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000" data-sal-delay="300"/>
              </div>
            </div>
                  <div className={[Classes.masonry].join(' ')}>
                    {instaNodes.map((post, id) =>
                      <div key={id} >
                        <a href={post.url} target="_blank">
                          <img src={post.image} alt={post.caption} className={Classes.image} />
                          {/* <Img fluid={post.node.localFile.childImageSharp.fluid} alt={post.node.caption} className={Classes.image}/> */}
                         {/* <p className={Classes.text}>
                            {post.node.caption.includes('smk') ? "National Gallery of Denmark" 
                            : post.node.caption.includes('botaniskhave') ? "Botanical Garden"
                            : post.node.caption.includes('rosenborg') ? "Rosenborg Castle" 
                            : post.node.caption.includes('østreanlæg') ? "Østre Anlæg" 
                            : post.node.caption.includes('statensnaturhistoriskemuseum') ? "Østre Anlæg" 
                            : post.node.caption.includes('kongenshave') ? "Kings Garden" 
                            : post.node.caption.includes('davidssamling') ? "The David Collection" 
                            : null}
                          </p> */}
                        </a>
                      </div>
                    )}
                </div>
            </SexySection>
        </SbEditable>
    )
}

export default InstagramFeed
