import React from 'react'
import SbEditable from 'storyblok-react'
import SexySection from '../ui/sexySection/sexySection'
import Classes from './faq.module.scss'
import Question from './question/question'

const Faq = (props) => {

    const pb = props.blok
    const questions = pb.question
    let delay = 100

    return (
        <SbEditable content={props.blok}> 
            <SexySection>
                <div className="row center">
                    <div className={["col col col-xs-12 col-md-10 col-lg-8"].join(' ')}>
                        <div className={Classes.faq}>
                            {questions.map((question, i) =>
                                <div key={i} data-sal="fade" data-sal-easing="ease" data-sal-duration="1000" data-sal-delay={delay = delay + 50}>
                                    <Question  question={question}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </SexySection> 
        </SbEditable>
    )
}

export default Faq