import React, {useState, useEffect} from 'react'
import Classes from'./question.module.scss'

const Question = ({question, id}) => {

    const [maxHeight, setMaxHeight] = useState(0)
    const answerRef = React.createRef()

    const expandHandler = () => {
        if(answerRef.current && maxHeight === 0 ) {
            setMaxHeight(answerRef.current.firstChild.offsetHeight)
        } else if(answerRef.current && maxHeight > 0 ) {
            setMaxHeight(0)
        }
    }
    
    return (
        <div key={id} className={Classes.questionCard}>
            <div className={[Classes.question].join(' ')}>
                <h4 className={Classes.questionText}>{question.question}</h4>
                    <button className={["btn-unstyled", Classes.expandButton, maxHeight > 0 ? Classes.rotate : null].join(' ')} onClick={() => expandHandler()}>+</button>
            </div>
                <div ref={answerRef} className={[Classes.answer, maxHeight !== 0 ? Classes.visible : null ].join(' ')} style={{ maxHeight: maxHeight || 0 + 'px'}}> 
                    <p>{question.answer}</p>
                </div>
        </div>
        
    )
}

export default Question
