import React from 'react'
import AnyLink from '../../ui/AnyLink/AnyLink'
import ImageAll from '../../ui/imageAll/imageAll'
import CartIcon from './cartIcon'


const Footer = ({columns}) => {
  function createMarkup(text) {
    return {__html: text}
  }
  return (
    <footer>
      <div className="container-fluid container-fluid-padding">
        <div className="row end-xs text-right">
            {columns && columns.map((c, i) => {

              return (
                <div key={i} className="col col-xs-12 col-md-6 col-lg-3 col-xl-2 space-xs-up">
                  {c.title && <h4>{c.title}</h4>}
                  {c.footer_item && c.footer_item.map((item, i) => {
                    
                    let element = null

                    if(item.component === 'link') {
                      element = <AnyLink 
                                  key={i} 
                                  link={item.link.url || `/${item.link.story.full_slug}`} 
                                  linkTitle={item.link_title} 
                                  external={item.link.linktype === 'url'}
                                  />
                    } else if(item.component === 'text') {
                      element = <p key={i} dangerouslySetInnerHTML={createMarkup(item.text)}></p>
                    } else if(item.component === 'image') {
                      element = <ImageAll key={i} classes="img-fluid" image={item.image.filename} fluid maxWidth="500" alt={item.image.alt}/>
                    }

                    return(
                      <div key={i}>
                        {element}
                      </div>
                    )
                  })}
                </div>
              )
            })}
            {/* <div style={{height: "32px", overflow: "hidden"}}>
              <CartIcon />
              
            </div> */}
        </div>
      </div>
    </footer>
  )
}

export default Footer