import React, { useEffect } from 'react'
import { CookieBanner } from '@palmabit/react-cookie-law';

const CookieConsent = ({content}) => {

  const coockieBackgroundColor = content.cookie_banner_bg_color?.color || "#103b31";
  const coockieTextColor = content.coockie_text_color?.color || "#ffffff";

  const declineButtonBgColor = content.cookie_banner_decline_button_bg_color?.color || "#436059";
  const declineButtonTextColor = content.cookie_banner_decline_button_text_color?.color || "#ffffff";


  const acceptButtonBgColor = content.cookie_banner_accept_button_bg_color?.color || "#1EF50F";
  const accepteButtonTextColor = content.cookie_banner_accept_button_text_color?.color || "#000000";


  // declineButton.style.backgroundColor = "red";

  useEffect(() => {
    if(typeof document !== 'undefined') {
      const marketingCheckBox = document.querySelector('#check-marketing-cookies');
      marketingCheckBox && marketingCheckBox.click()

      const declineButton = document.getElementsByClassName('react-cookie-law-decline-btn');
      if(declineButton[0] && declineButton[0].style) {
        declineButton[0].style.backgroundColor = declineButtonBgColor;
        declineButton[0].style.border = declineButtonBgColor;
        declineButton[0].style.color = declineButtonTextColor;
      }
      
      const acceptButton = document.getElementsByClassName('react-cookie-law-accept-btn');
      if(acceptButton[0] && acceptButton[0].style) {
        acceptButton[0].style.backgroundColor = acceptButtonBgColor;
        acceptButton[0].style.border = acceptButtonBgColor;
        acceptButton[0].style.color = accepteButtonTextColor;
      }
    }
  }, [])
  

  useEffect(() => {
    // window.location.reload()
  }, [])


  const banner = content && <CookieBanner
    message={content.message}
    wholeDomain={true}
    policyLink={`/${content.privacy_link.story.full_slug}`}
    privacyPolicyLinkText={content.privacy_link_title}
    necessaryOptionText={content.necessary_option_text}
    statisticsOptionText={content.statistics_option_text}
    marketingOptionText={content.marketing_option_text}
    acceptButtonText={content.accept_title}
    declineButtonText={content.decline_title}
    showDeclineButton={true}
    showPreferencesOption={false}
    // onDeclinePreferences={alert('declined Preferences')}
    // onDeclineStatistics={alert('declined Static')}
    // onDeclineMarketing={alert('declined Marketing')}
    // onAcceptPreferences = {console.log("onAcceptPreferences fired!")}
    
    // onAccept = {() => {alert('onAccept Accepted!')}}
    // onAcceptStatistics = {() => {alert(' onAcceptStatistics Accepted!')}}
    // onAcceptMarketing = {() => {alert(' onAcceptMarketing Accepted!')}}

    styles={{
      dialog: { 
        backgroundColor: coockieBackgroundColor, 
        position: 'fixed', 
        width: '100%', 
        bottom: 0, 
        padding: '2rem',
        zIndex: '10'
      },
      container: {
        maxWidth: '85.625rem',
        padding: '0 0.9375rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        overflow: 'hidden',
        position: 'relative'
      },
      message: {
        fontSize: '1rem',
        width: '36.5625rem',
        color: coockieTextColor
      },
      policy: {
        color: coockieTextColor,
        fontSize: '1rem',
        textDecoration: 'underline',
      },
      selectPane: {
        display: 'block',
        padding: '1.875rem 0',
      },
      optionWrapper: {
        display: 'inline-block',
        position: 'relative',
        marginRight: '1.875rem'
      },
      optionLabel: {
        color: coockieTextColor,
        fontSize: '1rem'
      },
      checkbox: {
        position: 'absolute',
        top: '25%',
        transform: 'translateY(-50%)',
        left: '0px',
        width: '14px',
        height: '14px',
        zIndex: '2',
        cursor: 'pointer'
      },
      buttonWrapper: {
        position: 'absolute',
        top: '0',
        right: '0'
      },
      button: {
        padding: '0.5rem 1.5rem',
        borderRadius: '0',
        // color: '#fff',
        marginLeft: '1rem'
      }
    }}
  />


  return (
    banner || null
  )
  
}
  

export default CookieConsent

// LAV KNAPFARVER I COOKIE CONPONENET VHA. STYLE TAG //