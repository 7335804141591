import React, { useState, useEffect } from 'react'
import AnyLink from '../../../ui/AnyLink/AnyLink'

const MenuItem = ({m, Classes, navbarController}) => {

  const [expanded, setExpanded] = useState(false)
  const [submenuMaxHeigt, setSubmenuMaxHeight] = useState(0)

  const submenu = React.createRef()

  useEffect(() => {
    
  }, [])

  const submenuExpandHandler = (e) => {
    // if(window.innerWidth < 960) {
    if(!e.target.classList.contains(Classes.menuItem)) {
      setExpanded(!expanded)
      if(submenu && submenu.current) {
        if(submenuMaxHeigt > 0) {
          setSubmenuMaxHeight(0)
        } else {
          let submenuHeight = 0
          const children = submenu.current.children
          for (var i = 0; i < children.length; i++) {
            submenuHeight = submenuHeight + children[i].clientHeight
          }
          setSubmenuMaxHeight(submenuHeight)

        }
      }
    }
  }
  return (
    <li className={[m.submenu && m.submenu.length > 0 ? Classes.hasSubmenu : null, expanded && submenuMaxHeigt > 0 ? Classes.expanded : null].join(' ')} >
      <div className="flex flex-start">
    <AnyLink 
      external={m.linkType == "url"}
      link={`/${m.link}`}
      linkTitle={m.linkTitle}
      classes={Classes.menuItem}
      anchor={m.anchor}
      navbarController={navbarController}
      />
      <button className={[Classes.expandBtn, "btn-unstyled"].join(' ')} onClick={(e) => submenuExpandHandler(e)}>
        <span className="sr-only">Expand</span>
      </button>
      </div>
      {m.submenu && m.submenu.length > 0 ?
      <ul ref={submenu} className={["list-unstyled list-inline", Classes.submenu].join(' ')} style={submenuMaxHeigt ? {maxHeight: submenuMaxHeigt + 'px'} : null}>
        {m.submenu.map((s, i) => (
          <li key={i}>
            <AnyLink 
              external={!s.link.story && true}
              link={`${s.link.story ? '/' : ''}${s.link.story ? (s.link.story.full_slug || '__') : s.link.url}`}
              linkTitle={s.link_title}
              classes={Classes.menuItem}
              />
              {s.subsubmenu && s.subsubmenu.length > 0 ?
                <ul className={["list-unstyled"].join(' ')}>
                  {s.subsubmenu.map((ss, i) => (
                    <li key={i}>
                    <AnyLink 
                      external={!ss.link.story && true}
                      link={`${ss.link.story ? '/' : ''}${ss.link.story ? (ss.link.story.full_slug || '__') : ss.link.url}`}
                      linkTitle={ss.link_title}
                      classes={Classes.menuItem}
                      />
                      </li>
                  ))}
                </ul>
              : null}
          </li>
        ))}
        
      </ul>
      : null}
      </li>
  )
}

export default MenuItem