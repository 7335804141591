import React, { useState, useEffect } from 'react'
import AnyLink from '../../ui/AnyLink/AnyLink'
import * as Classes from './navbar.module.scss'
import ImageAll from '../../ui/imageAll/imageAll'
import LangSwitcher from '../../ui/langSwitcher/langSwitcher'
import MenuItem from './menuItem/menuItem'

const Navbar = ({ menuItems, locales, logo, currentLocale, cta }) => {

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  
  const handleScroll = () => {
    const currentScrollPos = typeof window !== 'undefined' ? window.scrollY : null;
    setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 800) || currentScrollPos < 540 || navbarExpanded);
    setPrevScrollPos(currentScrollPos);
  };
  
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const [windowWidth, setWindowWidth] = useState()

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth))
  }, [])

  const [navbarExpanded, setNavbarExpanded] = useState(false)
  
  const navbarController = () => {
    setNavbarExpanded(!navbarExpanded);
    console.log("toggleNavbar fired")
  }

  return (
    <div className={[Classes.navbar, navbarExpanded && Classes.navExpanded].join(' ')} style={{transition: 'top 0.4s', top: visible ? '0' : '-90px' }}>
      <div className="container-fluid overflow-visible">
        <div className="row">
          <div className={[Classes.navbarInner, navbarExpanded ? Classes.expanded : Classes.closed, "col col-xs-12 flex space-between"].join(' ')}>
            <div className={Classes.mobileNav}>
              <div className={Classes.navbarLogo}>
              <AnyLink link={`/${currentLocale !== 'default' ? currentLocale : ''}`}>
                <ImageAll image={logo} alt="Logo" classes={Classes.logo} fluid maxWidth="500"/>
              </AnyLink>
              </div>

              {/* <CartIcon /> */}
              
              {/* { !loaded ?  <CartIcon /> && setLoaded(true) : null } */}
             
              {/* <script async src="https://portal.vizgu.com/vizgu-cart.js?e=nviz-cart&amp;o=f983a3b7-daec-4462-82a0-4ae64cc5f1cd&amp;c=/billet-ny/?nviz=checkout"></script>*/}

              {/* <script async src="https://portal.vizgu.com/vizgu-cart.js?e=nviz-cart&amp;o=f983a3b7-daec-4462-82a0-4ae64cc5f1cd&amp;c=window.encodeURIComponent(/billet-ny/?nviz=checkout)"></script> */}
              
              {/* { doku && vindue ? cart(vindue, doku, "nviz-cart", 'f983a3b7-daec-4462-82a0-4ae64cc5f1cd', 'https://portal.vizgu.com/vizgu-cart.js', `https://www.parkmuseerne.dk/billet-ny`) : null} */}

              <div className="flex center-xs">
              {locales &&
                <div className={["hidden-xs hidden-sm", Classes.langButton].join(' ')}>
                  <LangSwitcher locales={locales} currentLocale={currentLocale} />
                </div>
              } 
              <div className={Classes.cta}>
              {cta && cta.map((c, i) => 
                <AnyLink 
                  key={i}
                  classes={`btn btn-${c.primary_secondary}`}
                  link={c.link.url ? c.link.url : c.link.story.full_slug ? `/${c.link.story.full_slug}` : null} 
                  linkTitle={c.link_title}
                  external={c.link.url}
                  anchor={c.link.anchor}
                  />
              )}
             
              </div>
              <button className={[Classes.menuToggle, "btn-unstyled"].join(' ')} aria-controls="primary-menu" aria-expanded="false" onClick={() => setNavbarExpanded(!navbarExpanded)}>
                <span className="sr-only">
                  Primær menu
                </span>
                <span className={[Classes.iconBar, Classes.topBar].join(' ')}></span>
                <span className={[Classes.iconBar, Classes.middleBar].join(' ')}></span>
                <span className={[Classes.iconBar, Classes.bottomBar].join(' ')}></span>
              </button>
              </div>
            </div>

     
            
            {/* <img className={Classes.logo} src={logo} alt="Logo" /> */}
            <div className="container">
              <div className="row center-xs">
                <div className="col-xs-12 col-md-6 col-xl-4">
                <div className={Classes.mainMenu} id="primary-menu">
                
              <ul className="list-unstyled">
                {/* <li>
                {locales && windowWidth < '960' && 
                  <div className={Classes.langSwitcher}>
                    <LangSwitcher relative locales={locales} currentLocale={currentLocale}/>
                  </div>
                }
                </li> */}
              {menuItems && menuItems.map((m, i) => 
                <MenuItem key={i} m={m} Classes={Classes} navbarController={navbarController}/>
              )}
              </ul>
              {locales && 
                <div className={["hidden-md-up", Classes.LangButton].join(' ')}>
                  <LangSwitcher locales={locales} currentLocale={currentLocale}/>
                </div>
              }
            </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar