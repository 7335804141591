import React from 'react'
import Classes from './textWithMedia.module.scss'
import SbEditable from 'storyblok-react'
import AnyLink from '../ui/AnyLink/AnyLink'
import SexySection from '../ui/sexySection/sexySection'
import ImageAll from '../ui/imageAll/imageAll'

const TextWithMedia = (props) => {

  const pb = props.blok
  const external = pb.link.linktype === "url" || pb.link.linktype === "asset" ? pb.link.url : null
  const internal = pb.link.linktype === "story" ? pb.link.story && pb.link.story.full_slug : null
  const linkTitle = pb.linkText ? pb.linkText : null
  const text = pb.text && pb.text.content ? pb.text : null
  const image = pb.media.filename
  const youtubeLink = pb.youtubeLink
  // const name = pb.media.name
  const alt = pb.media.alt
  const imageTitle = pb.media.title
  const mirrored = pb.mirrored
  const bgColor = pb.bgColor
  const imageSize = pb.imageSize
  const centerContent = pb.centerContent
  const imageToEdge = pb.imageToEdge
  const icon = pb.icon || null

  const StoryblokClient = require('storyblok-js-client')
    let Storyblok = new StoryblokClient({
    accessToken: 'HJYqFBdZr08fxdAVgr1GMAtt'
  })

  const createMarkup = (text) => {
    return {
      __html: Storyblok.richTextResolver.render(text),
    }
  }
  
  const textArea = (
    <>
    {text || external || internal || linkTitle ?
      <div className={["col col-xs-12", (image || youtubeLink) && imageSize === "big" ? "col-lg-4" : (image || youtubeLink) && imageSize === "small" ? "col-lg-8" : image || youtubeLink ? "col-lg-6" : "col col-xs-12 col-md-10 col-lg-8"].join(' ')}>
        {icon ? <div data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000" data-sal-delay="300" className={!mirrored ? "flex end-xs" : null}>
          <ImageAll fluid image={icon.filename} maxWidth="500" classes={["img-fluid", Classes.icon].join(' ')} alt={icon.alt} /> 
          </div>
        : null }
        {text ?
        <div className="space-xs-up" dangerouslySetInnerHTML={createMarkup(text)} data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000" data-sal-delay="300"/>
        : null }
        {external || internal || linkTitle ?
          <div className={["space-xs-up"].join(' ')} data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000" data-sal-delay="300">
            <AnyLink external={external} link={internal ? `/${internal}` : external ? external : null} linkTitle={linkTitle} classes={"btn btn-line"}/>
          </div>
        : null }
      </div>
    : null }
    </>
  )

  const imageArea = (
    <>
      {image ? 
        <div className={["col col-xs-12", (text || external || internal) && imageSize === "big" ? "col-lg-8" : (text || external || internal) && imageSize === "small" ? "col-lg-4" : text || external || internal ? "col-lg-6" : null, mirrored ? "last-xs last-sm first-lg" : null].join(' ')} data-sal="fade" data-sal-easing="ease" data-sal-duration="1000" data-sal-delay="200">
          <ImageAll fluid maxWidth="1440" classes={[Classes.image, imageToEdge ? Classes.imageToEdge : null, imageToEdge && mirrored ? Classes.imageToEdgeMirr : null ].join(' ')} image={image} alt={alt} title={imageTitle}/>
        </div>
      : youtubeLink ?
          <div  className={["col col-xs-12", (text || external || internal) && imageSize === "big" ? "col-lg-8" : (text || external || internal) && imageSize === "small" ? "col-lg-4" : text || external || internal ? "col-lg-6" : null, mirrored ? "last-xs last-sm first-lg" : null].join(' ')} data-sal="fade" data-sal-easing="ease" data-sal-duration="1000" data-sal-delay="200">
            <div className="embed-responsive">
              <iframe src={`${youtubeLink}` + "?feature=oembed&controls=1&hd=1&autohide=1&rel=0&modestbranding=1&showinfo=0"} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" autoplay allowfullscreen/>
            </div>
        </div>
      : null }
    </>
  )

  return (
    <SbEditable content={pb}>
      <SexySection bgColor={bgColor}>
          <div className={["row middle-xs center", centerContent ? "text-center" : null, !mirrored && (image || youtubeLink) ? "text-right" : null ].join(' ')}>
            {mirrored ? imageArea : textArea}
            {mirrored ? textArea : imageArea}
          </div>
      </SexySection>
    </SbEditable>
  )
}

export default TextWithMedia

