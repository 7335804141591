import React from 'react'
import SbEditable from 'storyblok-react'
import SexySection from '../ui/sexySection/sexySection'
import ImageAll from '../ui/imageAll/imageAll'
import Classes from './header.module.scss'

const Header = (props) => {

  const pb = props.blok
  const textPosition = "bottomRight"
  const icon = pb.icon || null
  const minHeight = "600px"
  const text = pb.text ? pb.text : null
  const image = pb.bg_image || null
  const bgColor = pb.bg_color ? pb.bg_color : null
  const margin = pb.use_as_normal_blok
  const smallMarginBottom = pb.small_padding_bottom

  const StoryblokClient = require('storyblok-js-client')
  let Storyblok = new StoryblokClient({
  accessToken: 'HJYqFBdZr08fxdAVgr1GMAtt'
})

const createMarkup = (text) => {
  return {
    __html: Storyblok.richTextResolver.render(text),
  }
}

  return (
    <SbEditable content={pb}>
      <SexySection fullwidth  bgColor={bgColor} classes={[Classes.section, margin ? Classes.sectionMargin : null, smallMarginBottom ? Classes.smallMarginBottom : null].join(' ')}>
        <div className={["row flex end-xs", Classes.row].join(' ')}>
            <div className={["col col-xs-12 col-md-8 col-xl-5 break-word text-white end-xs flex", !textPosition ? "col-md-10 col-lg-8" : null, Classes.text].join(' ')}>
              <div className={textPosition ? textPosition : "text-center"}>

                {icon ? <div data-sal="slide-left" data-sal-easing="ease" data-sal-duration="1000" data-sal-delay="500"><ImageAll fluid image={icon.filename} maxWidth="500" classes={["img-fluid", Classes.icon].join(' ')} alt={icon.alt}/></div> : null }
                {text ? <div dangerouslySetInnerHTML={createMarkup(text)} data-sal="slide-left" data-sal-easing="ease" data-sal-duration="1000" data-sal-delay="200" /> : null }
              </div>
            </div>
            
          <div className={Classes.overlay}></div>
            {image ? <ImageAll fluid fullWidth backgroundImage quality="90" image={image.filename} maxWidth="1440" classes={[Classes.bgImage, image && bgColor ? Classes.imgOpacity : null].join(' ')} alt={image.alt} title={image.title} isHeader/> : null}
        </div>
        
      </SexySection>
    </SbEditable>
  )
}

export default Header
