import React from 'react'
import SbEditable from 'storyblok-react'
import SexySection from '../ui/sexySection/sexySection'
import Slide from './slide'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";


const Slider = (props) => {
    
    const pb = props.blok
    const slides = pb.slide
    const sliderHeight = pb.slider_height
    const useAsHeader = pb.use_as_header
    const autoplay = pb.deactivate_autoplay ? false : true
    const sliderSpeed = pb.slider_speed * 1000
    const sliderNarrow = pb.slider_narrow

    return (
        <SbEditable content={pb}>
            <SexySection fullwidth={!sliderNarrow} classes={useAsHeader ? "no-padding" : null}>
                <Carousel 
                    autoPlay={autoplay}
                    interval={sliderSpeed}
                    transitionTime={500}
                    useKeyboardArrows
                    infiniteLoop
                    showStatus={false}
                    showThumbs={false}
                    emulateTouch
                    showArrows={false}
                >
                {slides ? 
                    slides.map((slide, i) => (
                        <Slide slide={slide} sliderHeight={sliderHeight} key={i}/>
                    ))
                : null }
                </Carousel>
            </SexySection>
        </SbEditable>
    )
}

export default Slider
