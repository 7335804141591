import React from 'react'
import Classes from './slide.module.scss'
import ImageAll from '../ui/imageAll/imageAll'
import AnyLink from '../ui/AnyLink/AnyLink'

const Slide = ({ slide, sliderHeight }) => {

    const text = slide.text
    const image = slide.image.filename ? slide.image.filename : null
    const alt = slide.image.alt
    const bgColor = slide.color.color
    const external = slide.link.linktype === "url" || slide.link.linktype === "asset" ? slide.link.url : null
    const internal = slide.link.linktype === "story" ? slide.link.story && slide.link.story.full_slug : null
    const linkTitle = slide.link_text ? slide.link_text : null
    const buttonColor = slide.button_color ? slide.button_color : "btn-primary"
    const textPosition = slide.text_position

    const StoryblokClient = require('storyblok-js-client')
    let Storyblok = new StoryblokClient({
        accessToken: 'HJYqFBdZr08fxdAVgr1GMAtt'
    })

    const createMarkup = (text) => {
        return {
        __html: Storyblok.richTextResolver.render(text),
        }
    }

    return (
        <div className={["center", Classes.slide, sliderHeight === "sliderBig" ? Classes.sliderBig : sliderHeight === "sliderSmall" ? Classes.sliderSmall : null].join(' ')} style={{backgroundColor: bgColor ? `${bgColor}` : null}}>   
            <div className={["col  col-xs-12 col-md-10 col-lg-8 break-word", Classes.content, textPosition].join(' ')}>
                <div dangerouslySetInnerHTML={createMarkup(text)}/>
                <AnyLink external={external} link={internal ? `/${internal}` : external ? external : null} linkTitle={linkTitle} classes={["btn", buttonColor, Classes.button].join(' ')}/>
            </div>
            <ImageAll fluid fullWidth backgroundImage image={image} maxWidth="1080" classes={[Classes.bgImage, image && bgColor ? Classes.imgOpacity : null].join(' ')} alt={alt}/>
        </div>
    )
}

export default Slide
