import React from 'react'
import SbEditable from 'storyblok-react'
import SexySection from '../ui/sexySection/sexySection'

const Cart = (props) => {

  const pb = props.blok
  const fullwidth = pb.full_width || null


  const cart = (v, i, z, g, u, p) => {
    const script = i.createElement('script');
    script.async = true;
    script.src = `${u}?e=${z}&o=${g}&c=${v.encodeURIComponent(p)}`;
    i.getElementsByTagName('head')[0].appendChild(script);
  }


  return (
    <SbEditable content={pb}>
      <SexySection fullwidth={fullwidth}>
        <div className="container">
            <div className="row center">
                <div className="col col-xs-12">
                  {/* <script>
                    (function (v, i, z, g, u, p) {
                      const script = i.createElement('script');
                      script.async = true;
                      script.src = `${u}?e=${z}&o=${g}&c=${v.encodeURIComponent(p)}`;
                      i.getElementsByTagName('head')[0].appendChild(script);
                    })(
                    window,
                    document,
                    "nviz-cart",
                    'f983a3b7-daec-4462-82a0-4ae64cc5f1cd',
                    'https://portal.vizgu.com/vizgu-cart.js',
                    "/en/tickets-and-prices/?nviz=checkout"
                    );
                  </script> */}
                  {cart(window, document, "nviz-cart", 'f983a3b7-daec-4462-82a0-4ae64cc5f1cd', 'https://portal.vizgu.com/vizgu-cart.js', "/en/tickets-and-prices/?nviz=checkout")}
                </div>
            </div>
        </div>
      </SexySection>
    </SbEditable>
  )
}

export default Cart

