import React from 'react'
import "./style/app.scss"
import { useStaticQuery, graphql } from 'gatsby'
import Navbar from './navbar/navbar'
import Footer from './footer/footer'
import CookieConsent from './cookieConsent/cookieConsent'

const Layout = ({ children, lang, locales }) =>  {

  const data = useStaticQuery(graphql`
  query globalElements {
    navbar: allStoryblokEntry(filter: {field_component: {eq: "menu"}}) {
      nodes {
        content
        lang
      }
    }
    footer: allStoryblokEntry(filter: {field_component: {eq: "footer"}}) {
      nodes {
        content
        lang
      }
    }
    cookie: allStoryblokEntry(filter: {field_component: {eq: "cookie_consent"}}) {
      nodes {
        content
        lang
      }
    }
  }`)

  const nav = JSON.parse(data.navbar.nodes.find(n => n.lang === lang).content)
  const navbar = {
    ...nav, 
    menuItems: nav.menu_items.map(m => 
      {
        return {
          // link: m.link.story.full_slug,
          link: m.link.story?.full_slug ? m.link.story.full_slug : null,
          linkType: m.link.linktype,
          linkTitle: m.link_title,
          lang: lang,
          submenu: m.submenu,
          anchor: m.link.anchor
        }
      }
    )
  }
  
  const footer = JSON.parse(data.footer.nodes.find(n => n.lang === lang).content).columns
  const cookie = JSON.parse(data.cookie.nodes.find(n => n.lang === lang).content)

    return (
      <>
        <Navbar menuItems={navbar && navbar.menuItems} locales={locales} logo={navbar && navbar.Logo.filename} currentLocale={lang || 'default'} cta={navbar && navbar.CTA}/>
        {children}
        <Footer columns={footer}/>
        <CookieConsent content={cookie}/>
      </>
    )
}

export default Layout